<template>
  <div class="">
    <b-alert :variant="'primary'" show class="cursor-pointer">
      <div class="alert-body">
        <strong>
          <feather-icon icon="RepeatIcon" class="mr-25" />
          Take another look at that QR code—it’s more than just a scan. It’s the
          gateway to secure access and real-time monitoring of every visitor who
          enters our area. Every scan tells a story of protection and control.
        </strong>
      </div>
    </b-alert>
    <b-card>
      <b-row>
        <b-col cols="12" class="mb-2">
          <h5 class="mb-0">
            <b-badge pill variant="primary"> A </b-badge> QRCode
          </h5>
          <small class="text-muted">
            <strong>Pilih File QRCode Kamu untuk diaktifkan kembali.</strong>
          </small>
        </b-col>
        <b-col cols="12">
          <QRCodeReader @qr-code-decoded="reuseQRCode" />
        </b-col>
      </b-row>
    </b-card>
    <b-progress
      v-if="loadingCheckQR"
      key="info"
      animated
      value="100"
      variant="info"
      :class="'progress-bar-info mt-2'"
    />
    <b-row class="match-height mt-2">
      <b-col cols="12" xl="9" lg="8" md="7">
        <!-- <b-card>
          <YoGoodTable
            :fields="tableColumns"
            :data="tableData"
            :paginationOptions="{ enabled: true, perPage: 10 }"
            @edit="handleEdit"
            @delete="handleDelete"
          />
        </b-card> -->
        <b-card v-if="isAllowReuse">
          <b-row>
            <b-col cols="12" v-if="isAllowReuse">
              <b-alert :variant="'primary'" show class="cursor-pointer">
                <div class="alert-body">
                  <strong>
                    <feather-icon icon="BookIcon" class="mr-25" />
                    <b-spinner type="grow" small />
                    <b-spinner type="grow" small />
                    <b-spinner type="grow" small />
                    QRCode kamu sudah berhasil dimuat , Lanjutkan mengisi
                    schedule IN / Keterangan
                    <b-spinner type="grow" small />
                    <b-spinner type="grow" small />
                    <b-spinner type="grow" small />
                  </strong>
                </div>
              </b-alert>
            </b-col>
            <!-- User Info: Left col -->
            <b-col
              cols="21"
              xl="6"
              class="d-flex justify-content-between flex-column"
            >
              <!-- User Avatar & Action Buttons -->
              <div class="d-flex justify-content-start">
                <b-avatar
                  :src="require('@/assets/images/avatars/avatar.png')"
                  :text="userData.full_name"
                  :variant="`light-primary`"
                  size="104px"
                  rounded
                />
                <div class="d-flex flex-column ml-1">
                  <div class="mb-1">
                    <h4 class="mb-0">
                      {{ userData.full_name || 'full name' }}
                    </h4>
                    <span class="card-text">{{
                      userData.email || 'email'
                    }}</span>
                  </div>
                  <div class="d-flex flex-wrap">
                    <b-button
                      :to="{
                        name: 'apps-users-edit',
                        params: { id: userData.id }
                      }"
                      disabled
                      variant="primary"
                      block
                    >
                    </b-button>
                  </div>
                </div>
              </div>

              <!-- User Stats -->
              <!-- <div class="d-flex align-items-center mt-2">
                <div class="d-flex align-items-center mr-2">
                  <b-avatar variant="light-success" rounded>
                    <feather-icon icon="TrendingUpIcon" size="18" />
                  </b-avatar>
                  <div class="ml-1">
                    <h5 class="mb-0">-</h5>
                    <small>QR Count IN Area</small>
                  </div>
                </div>

                <div class="d-flex align-items-center">
                  <b-avatar variant="light-danger" rounded>
                    <feather-icon icon="TrendingUpIcon" size="18" />
                  </b-avatar>
                  <div class="ml-1">
                    <h5 class="mb-0">-</h5>
                    <small>QR Count Expired</small>
                  </div>
                </div>
              </div> -->
            </b-col>

            <!-- Right Col: Table -->
            <b-col cols="12" xl="6">
              <table class="mt-2 mt-xl-0 w-100">
                <tr>
                  <th class="pb-50">
                    <feather-icon icon="UserIcon" class="mr-75" />
                    <span class="font-weight-bold">Nomor KTP</span>
                  </th>
                  <td class="pb-50">
                    {{ userData.no_ktp ? userData.no_ktp.slice(0, 6) + '****' + userData.no_ktp.slice(-4) : '' }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon icon="CheckIcon" class="mr-75" />
                    <span class="font-weight-bold">Status QRCode</span>
                  </th>
                  <td class="pb-50 text-capitalize">{{ userData.status }}</td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon icon="StarIcon" class="mr-75" />
                    <span class="font-weight-bold">Last IN</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    {{
                      moment(userData.last_in, 'YYYY-MM-DDTHH:mm:ssZ').format(
                        'DD MMMM YYYY  HH:mm'
                      ) || '-'
                    }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon icon="FlagIcon" class="mr-75" />
                    <span class="font-weight-bold">Perusahaan</span>
                  </th>
                  <td class="pb-50">
                    {{ userData.from_company }}
                  </td>
                </tr>
                <tr>
                  <th>
                    <feather-icon icon="PhoneIcon" class="mr-75" />
                    <span class="font-weight-bold">Nomor Whatsapp</span>
                  </th>
                  <td>
                    {{ userData.phone }}
                  </td>
                </tr>
              </table>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="12" md="5" xl="3" lg="4">
        <b-card no-body class="border-primary">
          <b-card-header
            class="d-flex justify-content-between align-items-center pt-75 pb-25"
          >
            <h5 class="mb-0">Current Plan</h5>
            <b-badge variant="light-primary"> Basic </b-badge>
            <small class="text-muted w-100">Guest Book</small>
          </b-card-header>

          <b-card-body>
            <ul class="list-unstyled my-1">
              <li>
                <span class="align-middle">Renew QRCode</span>
              </li>
              <li class="my-25">
                <span class="align-middle">QRCode Ter-Enkripsi</span>
              </li>
              <li class="my-25">
                <span class="align-middle">Top priority</span>
              </li>
              <li>
                <span class="align-middle">Security Support</span>
              </li>
            </ul>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              disabled
            >
            </b-button>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12" md="12" v-if="isAllowReuse">
        <b-card>
          <validation-observer ref="yoScheduleRule" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">
                  <b-badge pill variant="primary"> B </b-badge> Schedule IN
                </h5>
                <small class="text-muted">
                  <strong>Enter Your Planning.</strong>
                </small>
              </b-col>
              <b-col>
                <!-- Calendar for selecting the date -->
                <b-form-group label="">
                  <validation-provider
                    #default="{ errors }"
                    name="Schedule IN Date"
                    rules="required"
                  >
                    <b-calendar
                      v-model="form.schedule_in_date"
                      block
                      locale="id-ID"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- Time picker for selecting the time -->
                <b-form-group label="">
                  <validation-provider
                    #default="{ errors }"
                    name="Schedule IN Time"
                    rules="required"
                  >
                    <b-form-timepicker
                      v-model="form.schedule_in_time"
                      now-button
                      reset-button
                      locale="id-ID"
                      :hour12="false"
                      class="mt-2"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <strong>
                  <br />
                  <i>" Every scan tells a story of protection and control. "</i>
                </strong>
                <!-- <p>Selected Date: {{ form.schedule_in_date }}</p>
                <p>Selected Time: {{ form.schedule_in_time }}</p> -->
              </b-col>
            </b-row>
          </validation-observer>
        </b-card>
      </b-col>

      <b-col cols="12" md="12" v-if="isAllowReuse">
        <b-card>
          <validation-observer ref="yoScheduleOthers" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">
                  <b-badge pill variant="primary"> B </b-badge> Others
                </h5>
                <small class="text-muted">
                  <strong>Enter Your Descriptions / Others.</strong>
                </small>
              </b-col>
              <b-col md="12" cols="12">
                <b-form-group label="Ket. / Tujuan Kunjungan *" label-for="ket">
                  <validation-provider
                    #default="{ errors }"
                    name="Ket. / Tujuan Kunjungan *"
                    rules="required"
                  >
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        <feather-icon icon="CoffeeIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="ket"
                        v-uppercase
                        v-model="form.ket"
                        type="ket"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Example: Monthly Meeting K3A, Daily Checking, etc"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </b-card>
      </b-col>

      <b-col cols="12">
        <b-button
          :disabled="loadingRenewQR || !isAllowReuse"
          block
          @click="formSubmitted"
          variant="primary"
        >
          <b-spinner v-if="loadingRenewQR" small />
          Reuse data , Aktifkan dan Kirim QRCode Ke Whatsapp
          {{ userData.phone ? `+${userData.phone}` : `` }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import QRCodeReader from './QRCodeReader.vue'
import moment from 'moment'
import useJwt from '@/auth/jwt/useJwt'
import {
  BSpinner,
  BProgress,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BBadge,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BCard,
  BCalendar,
  BFormTimepicker,
  BAvatar,
  BCardHeader,
  BCardBody,
  BAlert
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

// import YoGoodTable from '@/views/components/YoGoodTable.vue'
export default {
  directives: {
    Ripple
  },
  components: {
    BAlert,
    BSpinner,
    BProgress,
    BCardHeader,
    BCardBody,
    BAvatar,
    // YoGoodTable,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BBadge,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BCard,
    BCalendar,
    BFormTimepicker,
    QRCodeReader
  },
  data() {
    return {
      BACKEND_URL: process.env.VUE_APP_BACKEND_URL,
      isAllowReuse: false,
      loadingCheckQR: false,
      loadingRenewQR: false,
      userData: {
        id: null,
        badge: '',
        nomor_whatsapp: '',
        ktp: '',
        email: '',
        nama_lengkap: '',
        alamat: '',
        perusahaan: ''
      },
      form: {
        schedule_in_date: '',
        schedule_in_time: '',
        ket: ''
      }

      /* good table */
      // tableColumns: [
      //   { label: 'Badge', field: 'badge' },
      //   { label: 'Nomor Whatsapp', field: 'nomor_whatsapp' },
      //   { label: 'KTP', field: 'ktp' },
      //   { label: 'Nama Lengkap', field: 'nama_lengkap' },
      //   { label: 'Alamat', field: 'alamat' },
      //   { label: 'Perusahaan', field: 'perusahaan' }
      // ],
      // tableData: [
      //   {
      //     badge: 'yogi',
      //     nomor_whatsapp: 'yogi',
      //     ktp: 'yogi',
      //     nama_lengkap: 'yogi',
      //     alamat: 'yogi',
      //     perusahaan: 'PT SAL',
      //   },
      //   {
      //     badge: 'yogi',
      //     nomor_whatsapp: 'yogi',
      //     ktp: 'yogi',
      //     nama_lengkap: 'yogi',
      //     alamat: 'yogi',
      //     perusahaan: 'PT PIL',
      //   },
      //   {
      //     badge: 'yogi',
      //     nomor_whatsapp: 'yogi',
      //     ktp: 'yogi',
      //     nama_lengkap: 'yogi',
      //     alamat: 'yogi',
      //     perusahaan: 'PT SAL',
      //   }
      // ]
    }
  },
  computed: {},
  methods: {
    moment,
    // handleEdit(row) {
    //   // Edit logic here
    // },
    // handleDelete(row) {
    //   // Delete logic here
    // },
    validationC() {
      return new Promise((resolve, reject) => {
        this.$refs.yoScheduleRule.validate().then((success) => {
          if (success) {
            resolve(true)
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Form Wajib diisi',
                icon: 'SaveIcon',
                variant: 'danger'
              }
            })
            reject()
          }
        })
      })
    },
    validationE() {
      return new Promise((resolve, reject) => {
        this.$refs.yoScheduleOthers.validate().then((success) => {
          if (success) {
            resolve(true)
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Form Wajib diisi',
                icon: 'SaveIcon',
                variant: 'danger'
              }
            })
            reject()
          }
        })
      })
    },
    async formSubmitted() {
      try {
        await this.validationC().then(async () => {
          await this.validationE().then(() => {
            this.saveData()
          })
        })
      } catch (error) {
        console.log('errors', error)
      }
    },
    async reuseQRCode(value) {
      this.loadingCheckQR = true
      console.log('Decoded QR Code:', value)
      await useJwt.http
        .post('visitor/reuseqr/get', {
          encrypted_key: value
        })
        .then(async (result) => {
          this.userData = result.data.visitor_qrcode
          const visitor_public = result.data.visitor_public
          const visitor_private = result.data.visitor_private
          let _userData = null
          if (this.userData.status.toLowerCase() === 'available') {
            const imageURL = `${this.BACKEND_URL}/dir_visitor_qrcode/logo.png`
            this.$swal({
              title: 'QRCode Available',
              text: 'QRCode Masih Aktif / Masih dapat digunakan untuk memasuki area PT SAL',
              imageUrl: imageURL,
              // eslint-disable-next-line global-require
              // imageWidth: 402,
              // imageHeight: 472,
              confirmButtonText: 'Close',
              showCancelButton: false,
              customClass: {
                // confirmButton: 'btn btn-primary',
                confirmButton: 'btn btn-primary'
                // cancelButton: 'btn btn-outline-danger ml-1'
              },
              buttonsStyling: false
            }).then(async (result) => {
              // if (result.value) {
              //   saveAs(imageURL, fileNames) //
              // }
            })
            this.isAllowReuse = false
          } else {
            this.$alert({
              title: 'QRCode ini dapat di aktifkan kembali',
              variant: 'success',
              icon: 'CheckIcon'
            })

            if (result.data.visitor_public) {
              _userData = visitor_public
            }

            if (result.data.visitor_private) {
              _userData = visitor_private
            }

            this.isAllowReuse = true
          }
          this.userData = {
            ...this.userData,
            ..._userData
          }
          this.loadingCheckQR = false
        })
        .catch((err) => {
          this.loadingCheckQR = false
        })
    },
    async saveData() {
      this.loadingRenewQR = true
      try {
        let payload = {
          visitor_qrcode_id: this.userData.id,
          phone: this.userData.phone,
          qrcode_link: this.userData.qrcode_link,
          schedule_in: `${this.form.schedule_in_date} ${this.form.schedule_in_time}`,
          last_schedule_in: `${this.form.schedule_in_date} ${this.form.schedule_in_time}`,
          ket: this.form.ket
        }
        await useJwt.http
          .put('visitor/reuseqr/renew', payload)
          .then(async (_) => {
            const successData = {
              full_name: this.userData.full_name,
              phone: this.userData.phone
            }

            // Store the data in Vuex
            this.$store.commit('visitor/SET_SUCCESS_DATA', successData)

            this.$router.push({
              path: '/planning/reuse-qrcode/success'
            })
          })
          .catch((err) => {
            this.loadingRenewQR = false
          })
      } catch (error) {
        console.log('errors:', error)
        this.loadingRenewQR = false
      }
    }
  },
  created() {}
}
</script>
