<template>
  <div>
    <BFormFile
      id="idreuseqrcode"
      v-model="qrcode"
      ref="fileInput"
      placeholder="Pilih File QRCode Kamu untuk diaktifkan kembali or drop it here..."
      drop-placeholder="Drop file here..."
      accept=".jpeg, .jpg, .png"
      @change="onFileChange"
      no-drop
    />
    <!-- Display QR code result and Remove button if file is uploaded -->
    <!-- <div v-if="qrCodeResult">
      <button @click="removeFile" class="btn btn-danger mt-2">
        Remove File
      </button>
    </div> -->
  </div>
</template>

<script>
import jsQR from 'jsqr'
import { BFormFile } from 'bootstrap-vue'

export default {
  components: {
    BFormFile
  },
  data() {
    return {
      qrcode: null,
      qrCodeResult: null
    }
  },
  methods: {
    // Method to remove the uploaded file and clear the QR code result
    removeFile() {
      this.qrCodeResult = null // Clear the QR code result
    },
    async onFileChange(event) {
      const file = event.target.files[0]
      console.log('file', file)
      if (file) {
        const imgUrl = URL.createObjectURL(file)
        const img = new Image()

        img.onload = () => {
          const canvas = document.createElement('canvas')
          const ctx = canvas.getContext('2d')

          // Set the canvas width and height to the image dimensions
          canvas.width = img.width
          canvas.height = img.height

          // Draw the image onto the canvas
          ctx.drawImage(img, 0, 0)

          // Get the image data from the canvas
          const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)

          // Use jsQR to decode the QR code from the image data
          const code = jsQR(imageData.data, imageData.width, imageData.height)
          if (code) {
            this.qrCodeResult = code.data // Set the decoded QR code result
            this.$emit('qr-code-decoded', code.data) // Emit the decoded value to the parent
          } else {
            this.qrCodeResult = 'No QR code found.'
          }

          // Release the object URL
          URL.revokeObjectURL(imgUrl)
          this.$refs.fileInput.value = ''
        }

        img.src = imgUrl // Set the src of the image to initiate loading
        // ** Reset the file input so the same file can be uploaded again **
      }
      // this.qrcode = null
    }
  }
}
</script>

<style scoped>
/* Add some styles if needed */
input[type='file'] {
  margin: 10px 0;
}
</style>
